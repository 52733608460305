const appColumns = [
  {
    key: 'Crid',
    label: 'CRID',
    show: true,
    headerTitle: 'CRID',
    sortable: true,
    thStyle: 'min-width:200px',
    tdClass: 'text-left',
    summed: false,
  },
  {
    key: 'Iurl',
    label: 'IURL',
    show: true,
    headerTitle: 'IURL',
    thStyle: 'min-width:250px',
    sortable: true,
    summed: false,
  },
  {
    key: 'AdType',
    label: 'Ad Type',
    show: true,
    headerTitle: 'Ad Type',
    thStyle: 'width:130px',
    tdClass: 'text-center',
    sortable: true,
    summed: false,
  },
  {
    key: 'Env',
    label: 'Environment',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-center',
    thStyle: 'width:90px',
  },
  {
    key: 'GeoedgeAlerts',
    label: 'Geoedge Alerts',
    show: true,
    headerTitle: 'Geoedge Alerts',
    sortable: true,
    tdClass: 'text-right',
    summed: false,
  },
]
export default appColumns
